<template>
	<div>
		<el-dialog
			:title="title"
			:visible.sync="open"
			width="700px"
			@close="commitOnClose"
		>
			<el-form
				ref="saveOrEditForm"
				:model="saveData"
				style="width:500px"
				:rules="rules"
				label-width="160px"
			>
				<el-form-item label="sn号">
					{{ saveData.sn }}
				</el-form-item>
				<el-form-item label="活动名称">
					{{ saveData.activityName }}
				</el-form-item>
				<el-form-item label="商户编号" prop="">
					<el-input
						v-model="saveData.merchantNo"
						placeholder="请输入商户编号"
						@keyup.enter.native="getMerchantName"
						@blur="getMerchantName"
					/>
				</el-form-item>
				<el-form-item label="商户名称">
					{{ merchantName }}
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitCheck">确定</el-button>
				<el-button type="primary" @click="cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { TerminalApi } from "@/api";
export default {
	name: "CustomForm",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		isAdd: {
			type: Boolean,
			default: true
		},
		selectRow: {
			type: Object,
			default: function() {
				return {};
			}
		},
		onFresh: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			title: "",
			saveData: {},
			options: [],
			open: false,
			merchantNo: "",
			merchantName: "",
			rules: {
				type: [
					{
						required: true,
						message: "请选择业务类型",
						trigger: "change"
					}
				],
				userRole: [
					{
						required: true,
						message: "请选择配置对象",
						trigger: "change"
					}
				],
				merchantNo: [
					{
						required: true,
						message: "请输入商户编号",
						trigger: "blur"
					}
				]
			}
		};
	},
	watch: {
		onFresh: function(value) {
			this.open = value;
			if (value) {
				this.resetData();
			}
		}
	},
	mounted() {},
	methods: {
		async remoteMethod(query) {
			if (query != "") {
				let res = await TerminalApi.terminalInfo.queryMerNameList(
					query
				);
				if (res.success) {
					this.options = res.data;
				}
			} else {
				this.options = [];
			}
		},
		async resetData() {
			this.saveData.merchantNo = "";
			this.saveData = this.selectRow;
		},
		submitCheck() {
			if (!this.merchantName) {
				this.Message("请输入商户编号");
				return false;
			}
			this.$refs.saveOrEditForm.validate(valid => {
				if (valid) {
					this.submitForm();
				} else {
					return false;
				}
			});
		},
		async submitForm() {
			let result = await TerminalApi.terminalInfo.bindSn(this.saveData);
			if (result.success) {
				this.Message.success("绑定成功");
				this.commitOnClose();
			}
		},
		cancel() {
			this.saveData = {};
			this.commitOnClose();
		},
		async getMerchantName() {
			let res = await TerminalApi.terminalInfo.queryMerchantName(
				this.saveData.merchantNo
			);
			if (res.success) {
				this.merchantName = res.data.merchantName;
			} else {
				this.merchantName = "";
				this.Message.error(res.message);
			}
		},
		commitOnClose() {
			this.merchantName = "";
			this.$emit("on-close");
		}
	}
};
</script>

<style scoped></style>
